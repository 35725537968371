<template>
  <van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop-invite-list">
    <div class="pop-title">
      邀请记录
      <van-icon name="cross" class="pop-close" color="#fff" @click="show = false" />
    </div>
    <div class="body">
      <template v-if="hasData">
        <div class="model" v-for="item in list">
          <div class="head">
            <div class="psl">{{ item.time }}</div>
            <div class="plr">
              成功邀请
              <div class="number">{{ item.number }}</div>
              人
            </div>
          </div>
          <div class="list" v-for="citem in item.scheduleInvitationRecordListVO">
            <div class="flexline">
              <div class="img">
                <img alt :src="citem.headImg" />
              </div>
              <div class="pub_onefull ct">
                <div class="username ellipsis">{{ citem.nickName }}</div>
                <div class="time">邀请时间：{{ citem.createTime }}</div>
                <div class="time" v-if="citem.inviteStatus == 2">进馆时间：{{ citem.recordTime }}</div>
              </div>
              <div class="right" :class="{ success: citem.inviteStatus == 2 }">
                <div class="end" v-if="citem.inviteStatus == 1">
                  <template v-if="citem.remainingDays == 0"> 今日23：59邀请失效 </template>
                  <template v-else>
                    距邀请失效仅剩 <span>{{ citem.remainingDays }}</span> 天
                  </template>
                </div>
                <div class="sub" :class="{ sub1: citem.inviteStatus == 1, sub2: citem.inviteStatus == 3 }" v-if="citem.inviteStatus == 1 || citem.inviteStatus == 3">{{ getName(citem.inviteStatus) }}</div>
                <div v-if="citem.inviteStatus == 2">邀请成功</div>
              </div>
            </div>
            <div class="info" v-if="citem.failMsg">
              <img alt src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/info.png" class="infologo" />
              {{ citem.failMsg }}
            </div>
          </div>
        </div>
      </template>
      <div v-else class="nodata">
        <div class="noimg">
          <img alt src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/none2.png" />
        </div>
        <div class="notips">暂无邀请记录</div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin'
export default {
  mixins: [userMixin],
  data() {
    return {
      show: false,
      list: [
        {
          time: '2023-1',
          number: 0,
          scheduleInvitationRecordListVO: [
            // {
            //     inviteStatus:1,
            //     nickName:'223',
            //     createTime:'2023-01-14',
            //     recordTime:'2023-01-14',
            //     remainingDays:0,
            //     headImg:'https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/sharebg-teacher.png'
            // }
          ],
        },
      ],
      hasData: true,
    }
  },
  methods: {
    getName(v) {
      const json = {
        1: '待进馆',
        2: '邀请成功',
        3: '已失效',
        4: '邀请失败',
      }
      return json[v]
    },
    getData() {
      this.$axios
        .post(this.baseURLApp + '/venue/invitation/venueNewUserInviteRecord', {
          inviteRewardsType: 'VENUE_NEW_USER_REWARED_FOR_INVITER',
          inviteType: 'VENUE_NEW_USER',
          hasWx: true,
          userId: this.userId,
          token: this.token,
          venueId: this.storeId,
        })
        .then((res) => {
          this.hasData = res.data.length > 0
          this.list = res.data
        })
    },
    open() {
      this.getData()
      this.show = true
    },
  },
  created() {
    this.storeId = this.$route.query.venueId
  },
}
</script>
<style lang="less" scoped>
.pop-invite-list {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;

  .pop-title {
    color: #432f17;
    font-weight: bold;
    background: linear-gradient(180deg, #fcf2da 0%, #ffffff 100%);
    font-size: 40px;
    text-align: center;
    line-height: 120px;
    position: relative;

    .pop-close {
      position: absolute;
      right: 32px;
      top: 38px;
      width: 44px;
      height: 44px;
      line-height: 44px;
      border-radius: 50%;
      background: rgba(39, 40, 49, 0.2);
      font-size: 28px;
    }
  }

  img {
    width: 100%;
    vertical-align: top;
  }

  .body {
    overflow: auto;
    height: 800px;
  }

  .nodata {
    .noimg {
      width: 184px;
      height: 120px;
      margin: 220px auto 0;
    }

    .notips {
      margin-top: 26px;
      font-size: 26px;
      color: #333;
      text-align: center;
    }
  }

  .model {
    padding: 0 32px 64px;

    .head {
      display: flex;
      justify-content: space-between;
      font-size: 26px;
      color: #242831;
      font-weight: bold;

      .number {
        border-radius: 4px;
        background: #ec6a2c;
        height: 32px;
        line-height: 34px;
        min-width: 12px;
        padding: 0 10px;
        display: inline-block;
        color: #fff;
        text-align: center;
        margin: 0 8px;
        font-size: 24px;
        position: relative;
        // top: -2px;
      }
    }

    .list {
      background: #fffaf8;
      border: 1px solid #fae4d5;
      margin-top: 24px;
      border-radius: 16px;
      padding: 28px;

      .flexline {
        display: flex;
      }

      .img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .ct {
        .username {
          flex: 1;
          color: #242831;
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 12px;
        }

        .time {
          color: #6c727a;
          font-size: 22px;
          margin-top: 10px;
        }
      }

      .right {
        flex-shrink: 0;

        .end {
          font-size: 22px;
          color: #6c727a;
          margin-bottom: 10px;

          span {
            color: #ff6e00;
          }
        }

        .sub {
          width: 104px;
          height: 40px;
          border-radius: 4px;
          font-size: 24px;
          text-align: center;
          color: #fff;
          line-height: 42px;
          float: right;
          border-radius: 4px;
        }

        .sub1 {
          background: linear-gradient(90deg, #799ff9 0%, #5f83f3 100%);
        }

        .sub2 {
          background: #9aa1a9;
        }
      }

      .success {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #ff6e00;
        font-size: 30px;
        align-self: center;
      }

      .info {
        color: #432f17;
        font-size: 22px;
        margin-top: 28px;
        display: flex;
        align-items: center;

        .infologo {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
